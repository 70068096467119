import { IESAuthUtilV2, Loader, abilityBuilder, AbilityContext, I18nContext, PageNotConfigured, useNetworkResponse, UserNotEntitled, ToastMsg, ProgressPopUp, ErrorPopUp, ProgressPopUpV2, CommonUtils, loginTypeKey, DeactivatedAccount, Button } from 'common-components-spa';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { Callback } from "./page";
import { IESConfig, IESOptions } from "./constants/IESConfig";
import {
  setAuthToken,
  setUserIdHeader,
  getHeaderInfo,
  getUserAbilities,
  getAppConfig,
  getSPAsInRegion,
  getOrganisationByUserId,
  getUserAbilitiesWithLevel,
  fetchRoleInOrg,
  fetchRoleInOrgv2,
} from "./network/ApiService";
import { serviceError } from "./constants/config";
import ReactGA from "react-ga";
import "./App.scss";
import { useHistory } from "react-router-dom";
import { isRedeemCodePage } from './utils/utils';
import LiveChatButton from './components/live-chat-button/LiveChatButton';
import { useAppcues } from './hooks/useAppcues'
import AppOutage from './page/app-outage/AppOutage';
import { forkJoin } from 'rxjs';
import {AHPMRIDS, allowedActivehubHosts} from './constants/appConstant'
import { AuthenticationContext } from './BaseApp';
import NoActiveHubAccount from './components/no-activehub-account/NoActivehubAccount';
import { getDateISOString, getLocalTimestamp, getUuid } from './utils/telemetryUtils';
export const AppContext = React.createContext(null);
export const warningIcon = `${process.env.PUBLIC_URL}/images/error-warning-icon.svg`;
export const confettiV2 = `${process.env.PUBLIC_URL}/images/confetti-bg-2.svg`;
const locale = navigator.language || "en-US";
const emptyAbilities = abilityBuilder([]);
const HTMLEntities = require('html-entities');


const Entities = ('AllHtmlEntities' in HTMLEntities) ? HTMLEntities.AllHtmlEntities : function() {
  this.encode = HTMLEntities.encode;
  this.decode = HTMLEntities.decode;
  this.decodeEntity = HTMLEntities.decodeEntity;

  return this;
}
const entities = new Entities();

const defaultToastObj = {
  title: "",
  msg: "",
  styleClass: "",
  delay: 0,
  autoHide: false,
  toastImg: "",
  show: false,
};

const defaultProgressPopupState = {
  done: false,
  waitingMsg: "",
  itemName: "",
  type: "",
  loadingImage: "",
  animationDuration: 4,
  onSuccess: () => {},
  status: null,
};

const defaultProgressPopupV2State = {
  isOpen: false,
  animationDuration: 4,
  loadingBg: "",
  info: "",
  subInfo: "",
  progressDone: false,
  onProgressBarFilled: "",
  progressErrorInfo: "",
  progressInfo: "",
  showProgressBar: "",
  propgresstypeIcon: "",
  isCloseBtn: false,
  infoClassName:"",
  propgresstypeIconCls:"",
  bodyClassName:""
}

const defaultErrorPopupState = {
  heading: "",
  info: "",
  btnName: "",
  btnStyle: false,
  cancelBtnName: "",
  image: "",
  subInfo: "",
};

const landingspadetails = {
  name: "Landing SPA",
  description: "View Landing SPA",
  launchPath: "/spa/landing",
  styleSheetElementBaseName: "Landing",
  spaId: "urn:pearson:gps:spa:landingspaid"
};

const homepageUrl = window.location.origin;
const preLoginUrl = `${window.location.origin}/#/login`;

const redirectToPreLogin = () => {
  window.location.assign(preLoginUrl);
}

const App = () => {

  const {iesSession, userId, isTokenExist, hasFatalError, setIsTokenExist} = useContext(AuthenticationContext);

  const history = useHistory();
  const [headerDetails, setHeaderDetails] = useState(null);
  const [footerDetails, setFooterDetails] = useState(null);
  const [spaDetailsList, setSpaDetailsList] = useState(null);
  // const [iesSession, setIesSession] = useState(null);
  const [userName, setUserName] = useState("");
  const [defaultSpaId, setdefaultSpaID] = useState(
    "urn:pearson:gps:spa:landingspaid"
  );
  const [selectedSpaId, setSelectedSpaId] = useState("");
  // const [userId, setUserId] = useState(null);
  const [region, setRegion] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [userAbilitiesWithLevel, setUserAbilitiesWithLevel] = useState(emptyAbilities)
  const {
    translate: userTranslate,
    loadUserTranslation,
    dispatch,
    translateHTML,
  } = useContext(I18nContext);
  const [translate, setTranslate] = useState(() => userTranslate);
  const [userlang, setUserLang] = useState("");
  const [wrapperLevelErrorPage, setWrapperLevelErrorPage] = useState(null);
  const [iframeLevelErrorPage, setIframeLevelErrorPage] = useState(null);
  const [toast, setToast] = useState(defaultToastObj);
  const [progressPopupState, setProgressPopupState] = useState(defaultProgressPopupState);
  const [progressPopupV2State, setProgressPopupV2State] = useState(defaultProgressPopupV2State);
  const [errorPopupVisibility, setErrorPopupVisibility] = useState(false);
  const [errorPopupState, setErrorPopupState] = useState(defaultErrorPopupState);
  const userAbilitiesResponse = useNetworkResponse();
  const headerInfoResponse = useNetworkResponse();
  const appConfigResponse = useNetworkResponse();
  const userRoleResponse = useNetworkResponse();
  const { appcuesData } = useAppcues();
  const userStatusResponse = useNetworkResponse();
  const [activeOrgs, setActiveOrgs] = useState([]);
  const [activeOrgGroups, setactiveOrgGroups] = useState([])

  // const [hasFatalError, setHasFatalError] = useState(false);

  const isChatWidgetPermitted = useMemo(() => {
    return userAbilitiesWithLevel.rules.some(element => (element.subject === "LIVE_CHAT_SUPPORT" && element.action === "READ"))
  }, [userAbilitiesWithLevel]);

  const GGWEvents = window.GGWLANGUAGE || {};

  const langSetAction = (lang) => {
    dispatch({ type: "setLanguage", payload: lang });
    setUserLang(lang);
  };

  GGWEvents.hasOwnProperty("subscribe") &&
    GGWEvents.subscribe("WRAPPER", "LANGCODE_CHANGED", (lang) =>
      loadUserTranslation(lang, langSetAction)
    );

  useEffect(() => {
    loadUserTranslation(
      localStorage.getItem("p-user-lang") || navigator.language,
      langSetAction
    );
  }, []);

  useEffect(() => {
    setTranslate(() => userTranslate);
  }, [userTranslate]);

  // const setToken = useCallback((tokenValue) => {
  //   setAuthToken(tokenValue);
  //   setIsTokenExist(true);
  // }, []);

  const fetchSpasInRegion = useCallback((ability) => {
    return getSPAsInRegion(region).subscribe(
      response => {
        console.log('spaDetailsList',response)
        const allowedSPA = response.filter(spa => {
          const { action, subject } = spa.ability;
          return action ? ability.can(action, subject) : false;
        });
        allowedSPA.push(landingspadetails);
        setSpaDetailsList(allowedSPA)
      },
      error => {
        if (error && error.data && error.data.status >= 500) {
          setSelectedSpaId(serviceError)
          setSpaDetailsList([])
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          setSpaDetailsList([])
        } else if (error && error.status === 403) {
          let response = [];
          response.push(landingspadetails);
          setSpaDetailsList(response);
        }
        console.log('Error in API', error)
      },
    );
  }, [region, setSpaDetailsList, iesSession, setSelectedSpaId])


  useEffect(() => {
    setUserIdHeader(userId);
  }, [userId]);

  useEffect(() => {
    clearUnsavedFormSession();
  }, []);

  useEffect(() => {
    if (appcuesData === null || !window.Appcues) return;
    const isAllowed = appcuesData.ALLOWED_ORIGIN.includes(window.location.hostname);
    if (!isAllowed) return
    window.Appcues.track("Login");
  }, [userId, window.Appcues])

  const clearUnsavedFormSession=()=>{
    sessionStorage.removeItem("unsavedFormDetail")
  }

  const buildAbility = (ability) => {
    const userAbilitiesWithLevel = abilityBuilder(ability.abilities);
    userAbilitiesWithLevel.level = ability.level;
    userAbilitiesWithLevel.permissionGroupId = ability.permissionGroupId
    setUserAbilitiesWithLevel(userAbilitiesWithLevel);
    window.GATEWAY = Object.assign(window.GATEWAY || {}, { userAbilitiesWithLevel });
  };

  const fetchUserAbilities = (userId, region) => {
    userAbilitiesResponse.dispatch({
      type: "STARTED",
    });
    getUserAbilitiesWithLevel(userId, region).subscribe(
      (response) => {
        userAbilitiesResponse.dispatch({
          type: "SUCCESS",
          payload: abilityBuilder(response.abilities),
        });
        buildAbility(response);
      },
      (error) => {
        userAbilitiesResponse.dispatch({
          type: "ERROR",
          payload: error,
        });
        if (error && error.data && error.data.status >= 500) {
          buildAbility([]);
          setSelectedSpaId(serviceError);
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          buildAbility([]);
        } else if (error && error.data && error.data.status === 403) {
          setWrapperLevelErrorPage(<UserNotEntitled />);
        }
      }
    );
  };

  const fetchHeaderInfo = () => {
    return getHeaderInfo().subscribe(
      (response) => {
        headerInfoResponse.dispatch({ type: "SUCCESS", payload: response });
        sessionStorage.setItem("regionDetail", JSON.stringify(response));
        if("urn:pearson:gps:region:gb" === response.regionId){
          fetchUserStatus(userId, response.regionId);
        }
        else{
          fetchUserAbilities(userId, response.regionId);
        }
        setHeaderDetails(response);
        setRegion(response.regionId);
      },
      (error) => {
        headerInfoResponse.dispatch({
          type: "ERROR",
          payload: error,
        });
        if (error && error.data && error.data.status >= 500) {
          setSelectedSpaId(serviceError);
          setHeaderDetails({
            name: "Pearson",
            description: "pearson global gateway",
            headerImageUrl: `${process.env.PUBLIC_URL}/images/Pearson-logo.png`,
          });
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          console.log("error 404 for region");
          setWrapperLevelErrorPage(<PageNotConfigured />);
        } else if (error && error.status === 403) {
          const host = window.location.hostname;
          const showAccountNotConfigured = allowedActivehubHosts.some(url => url === host);
          const errorComponent = showAccountNotConfigured ? (
           <NoActiveHubAccount />
          ) : (
            <div className="user-not-entitled-wrapper">
              <UserNotEntitled />
            </div>
          )
          console.log("error 403 for region");
          if (isRedeemCodePage) return;
          setWrapperLevelErrorPage(
            errorComponent
          );
        }
        console.log("Error in API", error);
      }
    )
  }

  function handleDeactivatedAccountAction() {
    window.open("https://www.pearsonschoolsandfecolleges.co.uk/activehub/report-an-issue-on-activehub", "_blank")
  }

  const fetchUserStatus = (userId, regionId) => {
    userStatusResponse.dispatch({
      type: 'STARTED'
    })
    getOrganisationByUserId(userId).subscribe(res => {

      if(regionId && regionId=="urn:pearson:gps:region:gb"){
        res = res?.filter(org=> (org?.productModelRegions && org?.productModelRegions.some(r=> AHPMRIDS.includes(r))) || (org?.orgGroupPmrId && AHPMRIDS.includes(org?.orgGroupPmrId)));
      }
      userStatusResponse.dispatch({
        type: 'SUCCESS',
        payload: res
      })
      const {productModelRegions, orgGroupPmrId} = res[0]
      if (!productModelRegions?.length > 0 && !orgGroupPmrId) {
        setWrapperLevelErrorPage(<DeactivatedAccount hasActionButton={true} handleActionButtonClick={handleDeactivatedAccountAction} actionButtonText={"Report an issue"} />)
      }
      else {
        let schoolsIds = [], trustIds = [];
        res.forEach(item => {
          if('organizationId' in item && item.organizationId) {
            schoolsIds.push(item);
          }
          if('organizationGroupId' in item && item.organizationGroupId) {
            trustIds.push(item);
          }
        });
        setActiveOrgs(schoolsIds);
        setactiveOrgGroups(trustIds);
        fetchUserRole(schoolsIds,trustIds);
      }
    },
    err => {
      userStatusResponse.dispatch({
        type: 'ERROR',
        payload: err
      })
      setWrapperLevelErrorPage(<DeactivatedAccount  hasActionButton={true} handleActionButtonClick={handleDeactivatedAccountAction} actionButtonText={"Report an issue"} />)
    })
  }

  useEffect(() => {
    if (!userId || !isTokenExist) return;
    setHeaderDetails(null);
    headerInfoResponse.dispatch({
      type: "STARTED",
    });
    const subscription = fetchHeaderInfo();

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isTokenExist]);

  useEffect(() => {
    if (!userId || !isTokenExist) return;
    let dispatchGAEvent = null;
    appConfigResponse.dispatch({ type: "STARTED" });
    const subscription = getAppConfig().subscribe(
      (response) => {
        ReactGA.initialize(response.GA_ID, { gaOptions: { userId: userId } });
        dispatchGAEvent = (e) => {
          ReactGA.event({
            category: e.detail.category,
            action: e.detail.action,
            label: e.detail.label,
            // value: "",
            // nonInteraction: "",
            // transport: ""
          });
        };
        window.addEventListener("GAEvent", dispatchGAEvent);
        appConfigResponse.dispatch({ type: "SUCCESS", payload: response });
      },
      (error) => { }
    );

    return () => {
      subscription.unsubscribe();
      dispatchGAEvent && window.removeEventListener("GAEvent", dispatchGAEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isTokenExist]);

  useEffect(() => {
    appConfigResponse.data && ReactGA.pageview(history.location.pathname);
  }, [history.location, appConfigResponse.data]);

  let popups = useMemo(() => {
    return {
      errorPopup: {
        setState: (state) => {
          setErrorPopupState({
            ...defaultErrorPopupState,
            onClosePopup: () => setErrorPopupVisibility(false),
            buttonAction: () => setErrorPopupVisibility(false),
            cancelButtonAction: () => setErrorPopupVisibility(false),
            ...state
          });
        },
        setVisibility: setErrorPopupVisibility,
      },
    };
  }, []);

  const unAuthorizedErrorMessage = useMemo(() => {
    return {
      isOpen: true,
      heading: translate("Sorry!!"),
      info: translate("You are not entitled for this action."),
      subInfo: translate("Please contact Support/Administrator."),
      btnName: translate("Okay, Got it"),
      btnStyle: true,
      image: `${process.env.PUBLIC_URL}/images/error-sorry.svg`,
      onClosePopup: () => setErrorPopupVisibility(false),
      buttonAction: () => setErrorPopupVisibility(false),
      cancelButtonAction: () => setErrorPopupVisibility(false),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeToast() {
    setTimeout(function () {
      setToast(defaultToastObj);
    }, toast.delay);
  }

  function closeProgressPopup() {
    setProgressPopupState(defaultProgressPopupState);
  }


  function closeProgressPopupV2() {
    setProgressPopupV2State(defaultProgressPopupV2State);
  }
  const isActiveHub = useMemo(() => {
    return region === 'urn:pearson:gps:region:gb'
  }, [region])

  useEffect(() => {
    if (!userId || !isActiveHub) return;
    const isHTTPS = CommonUtils.isHTTPS();
    if (!isHTTPS) return;
    sessionStorage.removeItem(loginTypeKey);
  }, [userId, isActiveHub])

  useEffect(() => {
    if (!iesSession || !region) return
    if (isActiveHub) {
      iesSession.onLogout(() => {
        if (window.dataLayer) {
          const logoutEvent = {
            "event": "logout",
            "event_category": "AppInteraction",
            "event_action": "Logout",
            "event_label": null,
            "transaction_local_dt": getLocalTimestamp(),
          }
          window.dataLayer.push(logoutEvent)
        }
          setIsTokenExist(false)
          // history.push('/login')
          redirectToPreLogin();
      });
    } else {
      iesSession.onLogout(() => {
        iesSession.login();
      });
    }
  }, [iesSession,isActiveHub,region])

  //for adding telemetry user identifier - Admin
  useEffect(() => {
    if (!isActiveHub || !iesSession || !userRole) return;
    if (activeOrgs.length > 0) {
      let googleLoggedInData = {
        person_id: window.piSession?.userId(), // User Id
        person_id_type: "IES", // User Authentication Type
        person_role_code: userRole, // User’s Role
        login_session_id: window.piSession?.getContextId(), // Login Session Id
        organization_id: activeOrgs[0].organizationId, // Organization / Institution Id
        organization_id_type: "GPS" // Organization Type
      }
      window.dataLayer.push(googleLoggedInData)
    }

  }, [activeOrgs, iesSession, isActiveHub, userRole])

  const redirectToAH = () => {
    let environment = ['dev', 'qa', 'stg', 'perf']
    let hostNameEnv = window.location.hostname.split(".")[0].split("-")[0];
    const activeHubUrl = environment.includes(hostNameEnv) ? `https://${hostNameEnv}.pet.ift.pearson-intl.com/activehub/index.html` : `https://activehub.pearson.com/activehub/index.html`;
    window.location.assign(activeHubUrl);
  }

  const fetchUserRole = (schoolsIds,trustIds) => {
    let productModelRegions = null, organizationId = null;
    if (schoolsIds.length > 0) {
      productModelRegions = schoolsIds[0].productModelRegions;
      organizationId = schoolsIds[0].organizationId
    }
      let organizationGroupId = null, orgGroupPmrId = null;
      if (trustIds.length > 0) {
        organizationGroupId = trustIds[0].organizationGroupId;
        orgGroupPmrId = trustIds[0].orgGroupPmrId
      }
      userRoleResponse.dispatch({ type: "STARTED" });
      userAbilitiesResponse.dispatch({type: "STARTED"});
      fetchRoleInOrgv2(userId, organizationId, organizationGroupId, productModelRegions, orgGroupPmrId
      ).subscribe(
        (res) => {
          const role = res?.role || null;
          if (role?.toUpperCase() === "LEARNER") {
            redirectToAH();
            return;
          }
          setUserRole(role)
          userRoleResponse.dispatch({ type: "SUCCESS", payload: role });
          userAbilitiesResponse.dispatch({type: "SUCCESS",payload: abilityBuilder(res.abilities),
          });
          buildAbility(res);
        },
        (error) => {
          userRoleResponse.dispatch({ type: "ERROR", payload: error });
          userAbilitiesResponse.dispatch({
            type: "ERROR",
            payload: error,
          });
          if (error && error.data && error.data.status >= 500) {
            buildAbility([]);
            setSelectedSpaId(serviceError);
          } else if (error && error.data && error.data.status === 401) {
            iesSession.logout();
          } else if (error && error.data && error.data.status === 404) {
            buildAbility([]);
          } else if (error && error.data && error.data.status === 403) {
            setWrapperLevelErrorPage(<UserNotEntitled />);
          }
        }
      );
    
  };

  const onRouteFromUnsavedFormHOF=(callBack)=>{
    const {isDirty, messageSubInfo, messageInfo}=JSON.parse(sessionStorage.getItem('unsavedFormDetail'))||{};
    if(!isActiveHub || !isDirty){
      return callBack?.()
    }
    setProgressPopupV2State({
      isOpen: true,
      isCloseBtn: false,
      info: translateHTML(
        `<strong>${messageInfo}</strong>`,
        { unsafe: true }
      ),
      subInfo: translateHTML(
        `<p class="pt-3">${messageSubInfo}</p>`,
        { unsafe: true }
      ),
      showSuccessImage: false,
      infoClassName:"pt-0",
      propgresstypeIcon: warningIcon,
      showProgressBar: false,
      loadingBg: confettiV2,
      propgresstypeIconCls: "mb-4",
      bodyClassName: "content-padding",
      customFooter: () => (
        <div className={`d-flex w-100 justify-content-center`}>
          <Button
            onClick={()=>{
              closeProgressPopupV2();
              callBack?.()
            }}
            className={`modalbtn button__v2 m-0 btnbg mt-5 mt-sm-0 button__primary__bordered mr-3`}
            value={"Leave this page"}
          />
          <Button
            onClick={closeProgressPopupV2}
            className={`modalbtn button__v2 ripple--v2 button__primary`}
            value={"Stay on this page"}
          />
        </div>
      ),
    })
  }


  const activeHubInfo = useMemo(() => {
    const userInfoObject = { isActiveHub, role: null }
    if (!isActiveHub || !userRoleResponse.data) return userInfoObject
    return { ...userInfoObject, role: userRoleResponse.data, activeOrgs: activeOrgs, activeOrgGroups: activeOrgGroups }
  }, [isActiveHub, userRoleResponse.data, activeOrgs, activeOrgGroups])

  useEffect(() => {
    window.ACTIVEHUB = activeHubInfo || {}
  }, [activeHubInfo])

  //for Telemetry login event
  useEffect(() => {
    if(!isActiveHub) return

    if (!isTokenExist) return
    
    if (window.dataLayer) {
      const loginEvent = {
        "event": "login",
        "event_category": "AppInteraction",
        "event_action": "Login",
        "event_label": null,
        "transaction_local_dt": getLocalTimestamp(),
      }
      window.dataLayer.push(loginEvent)
    }

  }, [isActiveHub, isTokenExist])

  const appContextValue = useMemo(() => {
    return {
      setToast,
      setProgressPopupState,
      headerDetails,
      setHeaderDetails,
      entities,
      footerDetails,
      setFooterDetails,
      region,
      setRegion,
      locale,
      iesSession,
      spaDetailsList,
      setSpaDetailsList,
      userName,
      setUserName,
      defaultSpaId,
      selectedSpaId,
      setSelectedSpaId,
      translate,
      translateHTML,
      setTranslate,
      userlang,
      wrapperLevelErrorPage,
      setWrapperLevelErrorPage,
      iframeLevelErrorPage,
      setIframeLevelErrorPage,
      unAuthorizedErrorMessage,
      userId,
      setProgressPopupV2State,
      closeProgressPopupV2,
      activeHubInfo,
      fetchSpasInRegion,
      fetchHeaderInfo,
      onRouteFromUnsavedFormHOF,
      ...popups
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    headerDetails,
    footerDetails,
    region,
    iesSession,
    spaDetailsList,
    userName,
    defaultSpaId,
    selectedSpaId,
    userTranslate,
    translate,
    setTranslate,
    translateHTML,
    userlang,
    wrapperLevelErrorPage,
    iframeLevelErrorPage,
    userId,
    activeHubInfo,
    fetchSpasInRegion
  ]);

  if (hasFatalError) {
    return <AppOutage />
  }

  if (
    !isTokenExist ||
    !userId ||
    userAbilitiesResponse.isLoading ||
    headerInfoResponse.isLoading
  ) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <AbilityContext.Provider value={userAbilitiesWithLevel}>
        <Suspense fallback={<Loader />}>
          {isChatWidgetPermitted && <LiveChatButton />}
          <ToastMsg
            title={toast.title}
            show={toast.show}
            msg={toast.msg}
            status={toast.status}
            onClose={() => closeToast()}
            styleClass={toast.styleClass}
            delay={toast.delay}
            autoHide={toast.autoHide}
            toastImg={toast.toastImg}
          />
          <ProgressPopUp
            isOpen={progressPopupState.isOpen}
            waitingMsg={progressPopupState.waitingMsg}
            done={progressPopupState.done}
            itemName={progressPopupState.itemName}
            type={progressPopupState.type}
            successMsg={progressPopupState.successMsg}
            animationDuration={progressPopupState.animationDuration}
            onSuccess={progressPopupState.onSuccess}
            status={progressPopupState.status}
            loadingImage={progressPopupState.loadingImage}
            headerContent={progressPopupState.headerContent}
            footerCustomElement={progressPopupState.footerCustomElement}
          />
          <ProgressPopUpV2
            isOpen={progressPopupV2State.isOpen}
            animationDuration={progressPopupV2State.animationDuration}
            loadingBg={progressPopupV2State.loadingBg}
            info={progressPopupV2State.info}
            subInfo={progressPopupV2State.subInfo}
            progressDone={progressPopupV2State.progressDone}
            onProgressBarFilled={progressPopupV2State.onProgressBarFilled}
            progressErrorInfo={progressPopupV2State.progressErrorInfo}
            progressInfo={progressPopupV2State.progressInfo}
            showProgressBar={progressPopupV2State.showProgressBar}
            propgresstypeIcon={progressPopupV2State.propgresstypeIcon}
            customFooter={progressPopupV2State.customFooter}
            footerClass={progressPopupV2State.footerClass}
            isCloseBtn={progressPopupV2State.isCloseBtn}
            closePopup={progressPopupV2State.closePopup}
            showSuccessImage={progressPopupV2State.showSuccessImage}
            infoClassName={progressPopupV2State.infoClassName}
            propgresstypeIconCls={progressPopupV2State.propgresstypeIconCls}
            bodyClassName={progressPopupV2State.bodyClassName}
          />
          <ErrorPopUp
            isOpen={errorPopupVisibility}
            heading={errorPopupState.heading}
            info={errorPopupState.info}
            btnName={errorPopupState.btnName}
            buttonAction={errorPopupState.buttonAction}
            btnStyle={errorPopupState.btnStyle}
            cancelBtnName={errorPopupState.cancelBtnName}
            cancelButtonAction={errorPopupState.cancelButtonAction}
            cancelBtnStyle={errorPopupState.cancelBtnStyle}
            image={errorPopupState.image}
            closePopup={errorPopupState.onClosePopup}
            subInfo={errorPopupState.subInfo}
            infoClassName={errorPopupState.infoClassName}
            subInfoClassName={errorPopupState.subInfoClassName}
            renderExtraElement={errorPopupState.renderExtraElement}
          />
          <Callback />
        </Suspense>
      </AbilityContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
